import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { formatDate } from "../../helper/formatDate";
import { convertTime } from "../../helper/convertTime";
import { optionCountry } from "src/helper/optionCountry";
import { getSessionKey } from "src/helper/sessionServices";
import axios from "axios";
import { useLoader } from "src/context/LoaderContext";

export interface HighlightCardData {
  id: string;
  eventType: "online" | "offline" | "communication";
  subtype?: "Read" | "Important" | "Research";
  name: string;
  imageUrl: string;
  provience: string;
  country: string;
  region?: string;
  eventdate: string;
  eventtime: string | "";
  isFavorite: boolean;
  eventendtime: string;
  addhome: boolean;
  about: string;
  venue: string;
  querydetail: string;
  highligthdetail: [
    {
      type: string;
      title: string;
    }
  ];
  type: string;
  meeturl: string;
  HighlightInterestNFavourites: [
    {
      type: string;
    }
  ];
  isFavouriteFlag: boolean;
  highlightDetailFav: boolean;
  highlightDetailInterest: boolean;
  setTriggerGetHighlight: any;
  highlightcode: any;
}

function HighlightCard(props: HighlightCardData) {
  const navigate = useNavigate();
  const { loading, setLoading } = useLoader();
  const [isFavorite, setIsFavorite] = useState(false);
  // const [isAuthToken, setIsAuthToken] = useState(false)
  //console.log("highlights_Props", props)
  useEffect(() => {
    let filteredArray = props.HighlightInterestNFavourites
      ? props.HighlightInterestNFavourites.filter(
          (item) => item.type === "favourite"
        )
      : "";
    //const isFavoritePresent = filteredArray.length > 0;
    if (filteredArray.length > 0) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
    // setIsAuthToken(getSessionKey("authToken"))
    //console.log("isAuthToken",isAuthToken);
  }, [props]);

  const handleFavorite = (highlightId: string, highFavandIntObj: any) => {
    setLoading(true);
    if (isFavorite) {
      axios
        .delete(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite?id=${highFavandIntObj[0].id}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response: any) => {
          //setHighlightDetail(response?.data?.data[0]);
          if (response?.data?.status === "Success") {
            setIsFavorite(!isFavorite);
            props.setTriggerGetHighlight(true);
            // setLoading(false);
          }
        })
        .catch((error: any) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
          // setLoading(false);
        });
    } else {
      const payload = {
        highlightcode: props.highlightcode,
        userid: getSessionKey("authToken")?.id,
        type: "favourite",
      };
      axios
        .post(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response: any) => {
          //setHighlightDetail(response?.data?.data[0]);
          if (response?.data?.status === "Success") {
            setIsFavorite(!isFavorite);
            props.setTriggerGetHighlight(true);
            // setLoading(false);
          }
        })
        .catch((error: any) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
          // setLoading(false);
        });
    }
    // setIsFavorite(!isFavorite);
  };

  const getLabelByValue = (value: string) => {
    const item = optionCountry.find((item) => item.label === value);
    return item ? item.label : "Label not found";
  };

  return (
    <div className="bs-highlight-card">
      <Link to={`/highlight-detail?highlightcode=${props.highlightcode}`}>
        <div className="card-wrap">
          <div className="logo-wrap">
            <img src={`${props.imageUrl}`} alt={props.name} />
          </div>
          <div className="info-wrap">
            <div className="info-head">
              <strong className="title">{props.name}</strong>
              <div className="card-tag">
                {props.eventType === "communication" ? (
                  <>
                    <i className={props.subtype}></i>
                    <span>{props.subtype}</span>
                  </>
                ) : (
                  <>
                    <i
                      className={
                        props.eventType === "offline"
                          ? "Offline"
                          : props.eventType
                      }
                    ></i>
                    <span>
                      {props.eventType === "offline"
                        ? props.provience +
                          ", " +
                          getLabelByValue(props.country)
                        : props.eventType}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="timeline-list">
              <div className="timeline-item">
                <i className="date"></i>
                <span>{formatDate(props.eventdate)}</span>
              </div>
              {props.eventtime !== "" ? (
                <div className="timeline-item">
                  <i className="time"></i>
                  <span>
                    {convertTime(
                      props.eventtime,
                      props.eventendtime !== "" && props.eventendtime
                        ? props.eventendtime
                        : props.eventtime
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Link>
      {getSessionKey("authToken") ? (
        <button
          className="fav-btn"
          onClick={() => {
            handleFavorite(
              props.highlightcode,
              props.HighlightInterestNFavourites
            );
          }}
        >
          {isFavorite ? (
            <i className="favorite-filled"></i>
          ) : (
            <i className="favorite"></i>
          )}
        </button>
      ) : null}
    </div>
  );
}

export default HighlightCard;
