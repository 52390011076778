import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import UploadCard, {
  UploadCardProps,
} from "src/components/upload-card/UploadCard";
import * as yup from "yup";
import { useFormik } from "formik";
import { DocumentDataType } from "../../common/identity-proof/IdentityProof";
import { useContext, useEffect, useState } from "react";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { StepContext } from "../../DetailsForm";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/it";
import { formatDate } from "src/helper/formatDate";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

dayjs.extend(localizedFormat);
export interface PropsDataType {
  currentStep: number;
}
export interface CompanyDetailsDataType {
  companyName: string;
  companyIncorporationDate: Date | null;
  taxIdentificationNo: string;
  noOfUBOs: number;
  noOfLRs: number;
  tradeRegister: string;
  authSignatoriesOfCompany: string;
  articlesOfAssociation: string;
  financialStatements: string;
}

const initialValues: CompanyDetailsDataType = {
  companyName: "",
  companyIncorporationDate: null,
  taxIdentificationNo: "",
  noOfUBOs: 1,
  noOfLRs: 1,
  tradeRegister: "",
  authSignatoriesOfCompany: "",
  articlesOfAssociation: "",
  financialStatements: "",
};

function CompanyDetails(props: PropsDataType) {
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const [locale, setLocale] = useState<string>("en");
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    const currentLang = i18n.language;
    dayjs.locale(currentLang);
  }, [i18n.language]);
  const validationSchema = yup.object({
    companyName: yup
      .string()
      .required(`${t("companydetails_validationCompanyName")}`),
    companyIncorporationDate: yup
      .date()
      .required(`${t("companydetails_validationIncorporationDate")}`),
    taxIdentificationNo: yup
      .string()
      .required(`${t("companydetails_validationTaxIdentification")}`),
    tradeRegister: yup
      .mixed()
      .required(`${t("companydetails_validationTradeRegister")}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        (value) => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
            // console.log('value', value);
            // return supportedFormats.includes(value?.name.split('.').pop());
          }
          return true;
        }
      )
      .test("fileSize", "File size must not be more than 5MB", (value) => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true;
      }),
    authSignatoriesOfCompany: yup
      .mixed()
      .required(`${t("companydetails_validationAuthoritarySignatory")}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        (value) => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
            // console.log('value', value);
            // return supportedFormats.includes(value?.name.split('.').pop());
          }
          return true;
        }
      )
      .test("fileSize", "File size must not be more than 5MB", (value) => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true;
      }),
    articlesOfAssociation: yup
      .mixed()
      .required(`${t("companydetails_validationArticleDocument")}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        (value) => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
            // console.log('value', value);
            // return supportedFormats.includes(value?.name.split('.').pop());
          }
          return true;
        }
      )
      .test("fileSize", "File size must not be more than 5MB", (value) => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true;
      }),
    financialStatements: yup
      .mixed()
      .required(`${t("companydetails_validationFinancialStatement")}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        (value) => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
            // console.log('value', value);
            // return supportedFormats.includes(value?.name.split('.').pop());
          }
          return true;
        }
      )
      .test("fileSize", "File size must not be more than 5MB", (value) => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true;
      }),
  });
  const { loading, setLoading } = useLoader();
  const { step, setStep } = useContext(StepContext)!;
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [docUploadField, setDocUploadField] = useState<UploadCardProps[]>([
    {
      id: null,
      cardId: "01",
      uploadType: "Document",
      cardTitle: `${t("companydetails_drawerTradeTitle")}`,
      uploadText: `${t("companydetails_uploadDocumentsTitle")}`,
      helpText: `${t("companydetails_tradeRegster_tooltip")}`,
      popupTitle: `${t("companydetails_drawerTradeTitle")}`,
      popupDesc: `${t("companydetails_drawerTradeSubTitle")}`,
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "tradeRegister",
    },
    {
      id: null,
      cardId: "02",
      uploadType: "Document",
      cardTitle: `${t("companydetails_authorizedSignSubTitle")}`,
      uploadText: `${t("companydetails_uploadDocumentsTitle")}`,
      helpText: `${t("companydetails_authorizedSign_tooltip")}`,
      popupTitle: `${t("companydetails_authorizedSignTitle")}`,
      popupDesc: `${t("companydetails_uploadAuthorizedSign")}`,
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "authSignatoriesOfCompany",
    },
    {
      id: null,
      cardId: "03",
      uploadType: "Document",
      cardTitle: `${t("companydetails_articleAssociationTitle")}`,
      uploadText: `${t("companydetails_uploadDocumentsTitle")}`,
      helpText: `${t("companydetails_articles_tooltip")}`,
      popupTitle: `${t("companydetails_articleAssociationTitle")}`,
      popupDesc: `${t("companydetails_uploadArticleAssociation")}`,
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "articlesOfAssociation",
    },
    {
      id: null,
      cardId: "05",
      uploadType: "Document",
      cardTitle: `${t("companydetails_financialStatementTitle")}`,
      uploadText: `${t("companydetails_uploadDocumentsTitle")}`,
      helpText: `${t("companydetails_last2yrsFS_tooltip")}`,
      popupTitle: `${t("companydetails_financialStatementTitle")}`,
      popupDesc: "",
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "financialStatements",
    },
  ]);
  const [financialStatement1, setFinancialStatement1] =
    useState<UploadCardProps>({
      id: "",
      cardId: "06",
      uploadType: "Document",
      cardTitle: "Financial Statements1",
      uploadText: `${t("companydetails_uploadDocumentsTitle")}`,
      helpText: "This is a dummy tooltip",
      popupTitle: "Financial Statements1",
      popupDesc: "",
      uploadStatus: false,
      documentData: null,
      docFileObj: null,
      errorMsg: "",
      fieldKey: "financialStatements1",
    });

  const formik = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      // console.log('company details values: ', values)
      // console.log('companyDetailsDocuments: ', docUploadField)
      // console.log('authToken: ', getSessionKey("authToken"));

      console.log('getSessionKey("authToken")', getSessionKey("authToken"));
      let docPayload: any[] = [];
      docUploadField.forEach((element) => {
        let tempObj = {
          id: isEdit ? element?.id : null,
          userid: getSessionKey("authToken")
            ? getSessionKey("authToken").id
            : "",
          userdetailid:
            getSessionKey("authToken")?.userdetail !== undefined
              ? getSessionKey("authToken").userdetail.id
              : getSessionKey("authToken").userDetailId,
          documenturl: element?.documentData?.fileurl,
          documenttype: "verification",
          documentsubtype: element.cardTitle,
          fileType: element?.documentData?.filetype,
          fileName: element?.documentData?.filename,
        };
        docPayload.push(tempObj);
      });
      if (financialStatement1.uploadStatus) {
        let tempObj = {
          id: isEdit ? financialStatement1?.id : null,
          userid: getSessionKey("authToken")
            ? getSessionKey("authToken").id
            : "",
          userdetailid:
            getSessionKey("authToken")?.userdetail !== undefined
              ? getSessionKey("authToken").userdetail.id
              : getSessionKey("authToken").userDetailId,
          documenturl: financialStatement1?.documentData?.fileurl,
          documenttype: "verification",
          documentsubtype: financialStatement1.cardTitle,
          fileType: financialStatement1?.documentData?.filetype,
          fileName: financialStatement1?.documentData?.filename,
        };
        docPayload.push(tempObj);
      }

      const payload = {
        id: isEdit ? userDetails.id : null,
        userid: getSessionKey("authToken").id,
        userdetailid:
          getSessionKey("authToken")?.userdetail !== undefined
            ? getSessionKey("authToken").userdetail.id
            : getSessionKey("authToken").userDetailId,
        companyname: values.companyName,
        companyincorporatedate: String(
          new Date(values?.companyIncorporationDate!)
        ),
        taxnumber: values.taxIdentificationNo,
        numberofubo: values.noOfUBOs,
        numberoflr: values.noOfLRs,
        detailtype: "company",
        document: docPayload,
      };

      console.log("payload", payload);
      if (isEdit && getSessionKey("settingType") === "Company") {
        axios
          .put(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/lrdetail",
            payload,
            {
              headers: {
                Authorization: `Bearer ${
                  getSessionKey("authToken").accesstoken
                }`,
              },
            }
          )
          .then((res) => {
            // console.log('res', res);
            if (res.data.success) {
              setSessionKey("companyDetails", JSON.stringify(values));
              setSessionKey(
                "companyDetailsDocuments",
                JSON.stringify(docUploadField)
              );
              setLoading(false);
              if (getSessionKey("settingType") === "Company")
                navigate("/client-approve");
              else navigate("/signup/details-form/ubo-details");
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              navigate("/enter-pin");
            } else {
              console.error("Error fetching data:", error.message);
            }
          });
      } else {
        setSessionKey("companyDetails", JSON.stringify(values));
        setSessionKey("companyDetailsPayload", JSON.stringify(payload));
        setSessionKey(
          "companyDetailsDocuments",
          JSON.stringify(docUploadField)
        );
        setLoading(false);
        navigate("/signup/details-form/ubo-details");
        // axios
        //   .post(
        //     "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/lrdetail",
        //     payload,
        //     {
        //       headers: {
        //         Authorization: `Bearer ${
        //           getSessionKey("authToken").accesstoken
        //         }`,
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     // console.log('res', res);
        //     if (res.data.success) {
        //       setSessionKey("companyDetails", JSON.stringify(values));
        //       setSessionKey(
        //         "companyDetailsDocuments",
        //         JSON.stringify(docUploadField)
        //       );
        //       setLoading(false);
        //       navigate("/signup/details-form/ubo-details");
        //     }
        //   })
        //   .catch((error) => {
        //     setLoading(false);
        //     if (error.response.status === 403 || error.response.status === 401) {
        //       navigate("/enter-pin");
        //     } else {
        //       console.error("Error fetching data:", error.message);
        //     }
        //   });
      }
    },
  });

  const handleFileUpload = (
    file: any,
    fileData: DocumentDataType | null | undefined,
    index: any,
    secData?: any,
    fieldKey?: string,
    file1?: any,
    fileData1?: DocumentDataType | null
  ) => {
    console.log("handleFileUpload", file, fileData, index);
    if (fieldKey === "financialStatements") {
      setFinancialStatement1((prevState) => ({
        ...prevState,
        uploadStatus: true,
        documentData: fileData1,
        docFileObj: file1,
      }));
    }

    updateItemValue(index, "uploadStatus", true);
    updateItemValue(index, "documentData", fileData);
    updateItemValue(index, "docFileObj", file);
    formik.setFieldValue(docUploadField[index].fieldKey!, fileData?.fileurl);

    if (fileData === null) {
      formik.setFieldValue(`${fieldKey}`, "");
    } else {
      formik.setFieldValue(`${fieldKey}`, fileData?.fileurl);
    }
  };

  // Function to update a specific value in an item by index
  const updateItemValue = (
    index: number,
    key: keyof UploadCardProps,
    value: any
  ) => {
    setDocUploadField((prevItems) => {
      const updatedItems = [...prevItems];
      const updatedItem = { ...updatedItems[index] };
      updatedItem[key] = value;
      updatedItems[index] = updatedItem;
      return updatedItems;
    });
  };

  useEffect(() => {
    let tempUserDetails: any;
    let userDetails: any;
    let userDetailsDocuments: any;
    setStep(props.currentStep!);
    userDetailsDocuments = getSessionKey("companyDetailsDocuments");
    tempUserDetails = getSessionKey("userExistData")
      ? getSessionKey("userExistData")
      : getSessionKey("companyDetails");

    if (tempUserDetails !== null) {
      setLoading(true);
      console.log('getSessionKey("authToken")', getSessionKey("authToken"));
      if (
        getSessionKey("userRegStatus").toLowerCase() === "declined" ||
        getSessionKey("userRegStatus").toLowerCase() ===
          "document approval pending" ||
        getSessionKey("settingType") === "Company"
      ) {
        userDetails = filterItemsByType(tempUserDetails, "company")[0];
        formik.setFieldValue(
          "noOfUBOs",
          filterItemsByType(tempUserDetails, "ubo").length
        );
        formik.setFieldValue(
          "noOfLRs",
          filterItemsByType(tempUserDetails, "lr").length
        );
      } else {
        userDetails = tempUserDetails;
        userDetails.document = userDetailsDocuments;
        formik.setFieldValue("noOfUBOs", userDetails?.noOfUBOs);
        formik.setFieldValue("noOfLRs", userDetails?.noOfLRs);
      }
      setIsEdit(true);
      setUserDetails(userDetails);
      console.log("userDetails", userDetails, tempUserDetails);
      formik.setFieldValue(
        "companyName",
        getSessionKey("companyDetails") === null
          ? userDetails?.companyname
          : userDetails?.companyName
      );
      formik.setFieldValue(
        "companyIncorporationDate",
        getSessionKey("companyDetails") === null
          ? dayjs(userDetails?.companyincorporatedate)
          : dayjs(userDetails?.companyIncorporationDate)
      );
      formik.setFieldValue(
        "taxIdentificationNo",
        getSessionKey("companyDetails") === null
          ? userDetails?.taxnumber
          : userDetails?.taxIdentificationNo
      );

      let financialStatementsUpdated = false;
      // Document Prefill Logic
      userDetails?.document?.forEach((item: any, idx: number) => {
        const index = findIndexByKey(
          docUploadField,
          "cardTitle",
          getSessionKey("companyDetails") === null
            ? item.documentsubtype
            : item.cardTitle
        );
        // console.log('doc array index', index);

        getImageFileFromUrl(
          getSessionKey("companyDetails") === null
            ? item?.documenturl
            : item?.documentData?.fileurl,
          getSessionKey("companyDetails") === null
            ? item?.fileName
            : item?.documentData?.filename
        ).then((fileObj) => {
          // console.log('fileObj', formik.values.financialStatements, docUploadField[index].fieldKey, index);
          if (fileObj) {
            const prefillFileDataObj = {
              fileurl:
                getSessionKey("companyDetails") === null
                  ? item?.documenturl
                  : item?.documentData?.fileurl,
              filename:
                getSessionKey("companyDetails") === null
                  ? item?.fileName
                  : item?.documentData?.filename,
              filesize: "" + fileObj.size,
              filetype: fileObj.type,
            };

            if (item?.documentsubtype === "Financial Statements1") {
              setFinancialStatement1((prevState) => ({
                ...prevState,
                id: item.id,
                uploadStatus: true,
                documentData: prefillFileDataObj,
                docFileObj: fileObj,
              }));
            } else {
              updateItemValue(index, "uploadStatus", true);
              updateItemValue(index, "documentData", prefillFileDataObj);
              updateItemValue(index, "docFileObj", fileObj);
              updateItemValue(index, "id", item.id);

              if (prefillFileDataObj === null) {
                formik.setFieldValue(`${docUploadField[index].fieldKey}`, "");
              } else {
                formik.setFieldValue(
                  `${docUploadField[index].fieldKey}`,
                  getSessionKey("companyDetails") === null
                    ? item?.documenturl
                    : item?.documentData?.fileurl
                );
              }
            }

            if (
              docUploadField[index]?.fieldKey === "financialStatements" &&
              !financialStatementsUpdated
            ) {
              financialStatementsUpdated = true;
            }
            if (formik.isValid) {
              setLoading(false);
            }
          }
        });
      });
    }
  }, []);

  const filterItemsByType = (data: any, type: string) => {
    return data[0].userdetail.userlrdetail.filter(
      (item: any) => item.detailtype === type
    );
  };

  async function getImageFileFromUrl(imageUrl: any, fileName: any) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl, {
        mode: "no-cors",
      });
      // fetch(imageUrl);
      const blob = await response.blob();

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }

  const findIndexByKey = (
    array: UploadCardProps[],
    key: string,
    value: string
  ) => {
    return array.findIndex(
      (item: UploadCardProps) =>
        item[key as keyof UploadCardProps].toLowerCase() === value.toLowerCase()
    );
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="lyt-main typ-details-form">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <h1 className={`${styles.pgTitle}`}>
              {t("companydetails_pgTitle")}
            </h1>
          </div>
          <div className="bs-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-body">
                <div className="form-section">
                  <h3 className="frm-sec-title">
                    {t("companydetails_enterTitle")}
                  </h3>
                  <div className="form-group">
                    <FormControl variant="standard">
                      <TextField
                        id="companyName"
                        name="companyName"
                        placeholder={t("companydetails_companyName")}
                        label={t("companydetails_companyName")}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        helperText={
                          formik.touched.companyName &&
                          formik.errors.companyName
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <div className="bs-datepicker">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                        localeText={{
                          okButtonLabel: `${t(
                            "companydetails_calenderDoneBtn"
                          )}`,
                          cancelButtonLabel: `${t(
                            "companydetails_calenderCloseBtn"
                          )}`,
                        }}
                      >
                        <DatePicker
                          format="MM-DD-YYYY"
                          label={t("companydetails_incorporationDate")}
                          name="companyIncorporationDate"
                          className={
                            formik.errors.companyIncorporationDate &&
                            formik.touched.companyIncorporationDate
                              ? "Mui-error"
                              : ""
                          }
                          value={formik.values.companyIncorporationDate}
                          onChange={(date) =>
                            formik.setFieldValue(
                              "companyIncorporationDate",
                              date
                            )
                          }
                          // onClose={() => {
                          //   formik.setFieldTouched(
                          //     "companyIncorporationDate",
                          //     true
                          //   );
                          // }}
                          // maxDate={dayjs().add(0, 'day')}
                          disableFuture
                          slotProps={{
                            field: {
                              readOnly: true,
                            },
                          }}
                        />
                        {formik.touched.companyIncorporationDate &&
                        formik.errors.companyIncorporationDate ? (
                          <span className="MuiFormHelperText-root Mui-error">
                            {formik.errors.companyIncorporationDate}
                          </span>
                        ) : null}
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="form-group">
                    <FormControl variant="standard">
                      <TextField
                        id="taxIdentificationNo"
                        name="taxIdentificationNo"
                        placeholder={t("companydetails_taxNumber")}
                        label={t("companydetails_taxNumber")}
                        value={formik.values.taxIdentificationNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.taxIdentificationNo &&
                          Boolean(formik.errors.taxIdentificationNo)
                        }
                        helperText={
                          formik.touched.taxIdentificationNo &&
                          formik.errors.taxIdentificationNo
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <div className="bs-select">
                      <FormControl fullWidth>
                        <InputLabel className="input-label">
                          {t("companydetails_numberOfUbo")}
                        </InputLabel>
                        <Select
                          id="noOfUBOs"
                          name="noOfUBOs"
                          label={t("companydetails_numberOfUbo")}
                          value={formik.values.noOfUBOs}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.noOfUBOs &&
                            Boolean(formik.errors.noOfUBOs)
                          }
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                        {formik.touched.noOfUBOs && formik.errors.noOfUBOs ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {formik.touched.noOfUBOs && formik.errors.noOfUBOs}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="bs-select">
                      <FormControl fullWidth>
                        <InputLabel className="input-label">
                          {t("companydetails_numberOfLR")}
                        </InputLabel>
                        <Select
                          id="noOfLRs"
                          name="noOfLRs"
                          label={t("companydetails_numberOfLR")}
                          value={formik.values.noOfLRs}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.noOfLRs &&
                            Boolean(formik.errors.noOfLRs)
                          }
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                        {formik.touched.noOfLRs && formik.errors.noOfLRs ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {formik.touched.noOfLRs && formik.errors.noOfLRs}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h3 className="frm-sec-title">
                    {t("companydetails_uploadDocumentsTitle")}
                  </h3>
                  {docUploadField.map((item, index) => {
                    return (
                      <UploadCard
                        key={index}
                        {...item}
                        uploaded={item.uploadStatus}
                        onFileUpload={handleFileUpload}
                        index={index}
                        formikObj={formik}
                        financialStatement1={financialStatement1}
                      ></UploadCard>
                    );
                  })}
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  {t("companydetails_proceedBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
