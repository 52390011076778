import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { FormControl, TextField } from "@mui/material";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import axios from "axios";

function InterestPage() {
  const { i18n, t } = useTranslation(["Interest"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const maxLength = 180;
  const [inputValue, setInputValue] = useState("");
  const [expressInterestLimit, setExpressInterestLimit] = useState(0);
  const location = useLocation();
  const historyData: any = location.state;

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (value.length <= maxLength) {
      setInputValue(value);
    }
  };

  const handleClick = () => {
    console.log("inputValue", inputValue);
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };
    let payload: any = {
      userid: getSessionKey("authToken").id,
      companyid: historyData?.companyId,
      interestmessage: inputValue,
    };
    if (getSessionKey("userRole") === "advisor") {
      payload["investorid"] = getSessionKey("selectedClient")?.investor?.id;
    }
    axios
      .post(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activerequest",
        payload,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "express interest",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    // navigate("/chat-message-page");
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
    axios
      .get(`https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/configdetail`, {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        setExpressInterestLimit(Number(response.data.data[0].value));
      });
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className={`${styles.sectionExpress} bs-section typ-news`}>
              <div className={`${styles.headWrap}`}>
                <h3 className={`${styles.title}`}>{t("interest_pgTitle")}</h3>
                <p className={`${styles.subTitle}`}>
                  {t("interest_pgSubTitle")} {expressInterestLimit}{" "}
                  {t("interest_pgSubTitle2")}
                </p>
              </div>
              <div className="bs-form">
                <form>
                  <div className="form-body">
                    <div className="form-group">
                      <p className={`${styles.countText}`}>
                        {inputValue.length} / {maxLength}{" "}
                        {t("interest_characters")}
                      </p>
                      <FormControl variant="standard">
                        <TextField
                          id="message"
                          multiline
                          placeholder={t("interest_placeholder")}
                          name="message"
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-foot">
                    <Button
                      btnStyle="primary"
                      disabled={inputValue.trim().length === 0}
                      onClick={handleClick}
                    >
                      {t("interest_proceedBtn")}
                    </Button>
                  </div>
                </form>
              </div>
              <div className={`${styles.infoCont}`}>
                <i className={`${styles.error}`}></i>
                <span className={`${styles.desc}`}>
                  {t("interest_information")}
                </span>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default InterestPage;
