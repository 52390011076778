import Button from "src/components/common/button/Button";
import styles from "./style.module.scss";
import RadioGroupField, {
  RadioField,
} from "src/components/common/radio-group/RadioGroupField";
import { FormControl, Input, InputLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DocumentUpload from "src/components/common/document-upload/DocumentUpload";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { StepContext } from "../../DetailsForm";
import { useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/it";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";

dayjs.extend(localizedFormat);

export interface IdentityProofObj {
  id?: null | number | string;
  identityProofType: string;
  documentExpiryDate: Date;
  documentNo: string;
  documentFileURL: string | undefined;
  documentFileName: string | undefined;
  documentFileSize: string | undefined;
  documentFileType: string | undefined;
}

export interface DocumentDataType {
  id?: string | number;
  fileurl: string;
  filename: string;
  filesize: string;
  filetype: string;
}

export interface PropsDataType {
  currentStep?: number;
  postDataToParent?: (data: IdentityProofObj, file: any, fileData: any) => void;
  file?: any;
  documentData?: DocumentDataType | null;
  data?: any;
}

function IdentityProof(props: PropsDataType) {
  const { i18n, t } = useTranslation(["IdentityProof"]);
  const [locale, setLocale] = useState<string>("en");
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    const currentLang = i18n.language;
    dayjs.locale(currentLang);
  }, [i18n.language]);
  const [docType, setDocType] = useState("");
  const [prefillFileObj, setPrefillFileObj] = useState<any>(null);
  const [prefillUploadedFile, setPrefillUploadedFile] = useState<any>(null);
  const [previewFileURL, setPreviewFileURL] = useState<string | undefined>("");
  const [documentData, setDocumentData] = useState<DocumentDataType | null>({
    fileurl: "",
    filename: "",
    filesize: "",
    filetype: "",
  });
  const userData = getSessionKey("userData");
  const [file, setFile] = useState();
  const validationSchema = yup.object({
    ...(docType.toLowerCase() === "passport" && {
      passport: yup
        .string()
        .required(`${t("identityproof_validationPassportRequired")}`),
      expiryDatePassport: yup
        .date()
        .required(`${t("identityproof_validationPassportExpiry")}`),
      passportFile: yup
        .mixed()
        .required(`${t("identityproof_validationPassportDocument")}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          (value) => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
              // console.log('value', value);
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true;
          }
        )
        .test("fileSize", "File size must not be more than 5MB", (value) => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true;
        }),
    }),
    ...(docType.toLowerCase() === "identity card" && {
      idNum: yup
        .string()
        .required(`${t("identityproof_validationIdRequired")}`),
      expiryDateIDProof: yup
        .date()
        .required(`${t("identityproof_validationIdExpiry")}`),
      idFile: yup
        .mixed()
        .required(`${t("identityproof_validationIdDocument")}`),
    }),
  });
  const { step, setStep } = useContext(StepContext)!;
  const navigate = useNavigate();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      documentId: null,
      passport: "",
      expiryDatePassport: null,
      expiryDateIDProof: null,
      idNum: "",
      passportFile: null,
      idFile: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log('values', values);
      let identityProofObj: IdentityProofObj = {
        id: null,
        identityProofType: "",
        documentExpiryDate: new Date(),
        documentNo: "",
        documentFileURL: "",
        documentFileName: "",
        documentFileSize: "",
        documentFileType: "",
      };

      identityProofObj["documentFileURL"] = documentData?.fileurl;
      identityProofObj["documentFileName"] = documentData?.filename;
      identityProofObj["documentFileSize"] = documentData?.filesize;
      identityProofObj["documentFileType"] = documentData?.filetype;
      //identityProofObj["id"] = values.documentId!==null? values.documentId : values?.passportFile?.lastModified;
      identityProofObj["id"] = values.documentId;
      if (docType.toLowerCase() === "passport") {
        identityProofObj["identityProofType"] = `${t(
          "identityproof_passportTitle"
        )}`;
        identityProofObj["documentExpiryDate"] = new Date(
          values.expiryDatePassport!
        );
        identityProofObj["documentNo"] = values.passport;
      } else if (docType.toLowerCase() === "identity card") {
        identityProofObj["identityProofType"] = `${t(
          "identityproof_identityTitle"
        )}`;
        identityProofObj["documentExpiryDate"] = new Date(
          values.expiryDateIDProof!
        );
        identityProofObj["documentNo"] = values.idNum;
      }
      const authToken = getSessionKey("authToken");
      const userRole = getSessionKey("userRole");
      const userData = getSessionKey("userData");
      if (getSessionKey("settingType") === "Identity") {
        //console.log("identity-->",identityProofObj)
        let registerUserPayload = {};
        if (userRole === "advisor") {
          registerUserPayload = {
            id: userData.id,
            userid: userData.id,
            userdetailid: userData.userDetailId | userData.userdetail.id,
            lang_id: userData.lang_id,
            contact: userData.phn,
            role: userRole,
            //"country": residenceProofObj.country,
            firstname: userData.userdetail.firstname,
            lastname: userData.userdetail.lastname,
            //"residencecountry": residenceProofObj.country,
            //"zipcode": residenceProofObj.zipcode,
            //"city": residenceProofObj.city,
            gender: "",
            dateofbirth: null,
            linkDevice: "",
            img: userData.userdetail.img,
            //"street": residenceProofObj.streetArea,
            //"housenumber": residenceProofObj.houseNo,
            state: "",
            document: [
              {
                userid: userData.id,
                id: identityProofObj.id,
                userdetailid: userData.userDetailId | userData.userdetail.id,
                documenturl: identityProofObj.documentFileURL,
                documenttype: "Identity Proof",
                documentsubtype: identityProofObj.identityProofType,
                expirydate: String(
                  new Date(identityProofObj.documentExpiryDate)
                ),
                documentnumber: identityProofObj.documentNo,
                fileType: identityProofObj.documentFileType,
                fileName: identityProofObj.documentFileName,
                provience: "",
              },
            ],
          };
        } else if (userRole === "individual_investor") {
          registerUserPayload = {
            userid: userData.id,
            userdetailid: userData.userDetailId | userData.userdetail.id,
            lang_id: userData.lang_id,
            contact: userData.phn,
            role: userRole,
            firstname: userData.userdetail.firstname,
            lastname: userData.userdetail.lastname,
            residencecountry: userData.userdetail.document[1].country,
            gender: "",
            dateofbirth: null,
            linkDevice: "",
            img: userData.userdetail.img,
            state: "",
            document: [
              {
                userid: authToken.id,
                userdetailid: authToken.userDetailId | authToken.userdetail.id,
                documenturl: identityProofObj.documentFileURL,
                documenttype: "Identity Proof",
                documentsubtype: identityProofObj.identityProofType,
                expirydate: String(
                  new Date(identityProofObj.documentExpiryDate)
                ),
                documentnumber: identityProofObj.documentNo,
                fileType: identityProofObj.documentFileType,
                fileName: identityProofObj.documentFileName,
                provience: "",
                id: identityProofObj.id,
              },
              /* {
                userid: authToken.id,
                userdetailid: authToken.userDetailId | authToken.userdetail.id,
                documenturl: residenceProofObj.documentFileURL,
                documenttype: "Residence Proof",
                documentsubtype: residenceProofObj.documentType,
                expirydate: String(new Date(residenceProofObj.documentDate)),
                documentnumber: "",
                fileType: residenceProofObj.documentFileType,
                fileName: residenceProofObj.documentFileName,
                provience: residenceProofObj.province,
                region: residenceProofObj.region,
                country: residenceProofObj.country,
                zipcode: residenceProofObj.zipcode,
                city: residenceProofObj.city,
                street: residenceProofObj.streetArea,
                housenumber: residenceProofObj.houseNo,
                id: residenceProofObj.id
              }, */
            ],
          };
        } else if (userRole === "legalrepresent") {
          registerUserPayload = {
            userid: userData.id,
            userdetailid: userData.userDetailId | userData.userdetail.id,
            lang_id: userData.lang_id,
            role: "legalrepresent",
            lrpointofcontact: userData.contact,
          };
        }

        axios
          .put(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/userdetaildocument",
            registerUserPayload,
            {
              headers: {
                Authorization: `Bearer ${
                  getSessionKey("authToken").accesstoken
                }`,
              },
            }
          )
          .then((res) => {
            console.log("res", res);
            if (res.data.success) {
              //setLoading(false);
              console.log("userData", userData);
              userData.userdetail.document[0].documenturl =
                identityProofObj.documentFileURL;
              //userData.userdetail.document[0].documenttype = "Identity Proof",
              userData.userdetail.document[0].documentsubtype =
                identityProofObj.identityProofType;
              userData.userdetail.document[0].expirydate = String(
                new Date(identityProofObj.documentExpiryDate)
              );
              userData.userdetail.document[0].documentnumber =
                identityProofObj.documentNo;
              userData.userdetail.document[0].fileType =
                identityProofObj.documentFileType;
              userData.userdetail.document[0].fileName =
                identityProofObj.documentFileName;
              setSessionKey("userData", userData);
              navigate("/client-approve");
            }
          })
          .catch((error) => {
            //setLoading(false);
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              navigate("/enter-pin");
            } else {
              console.error("Error fetching data:", error.message);
            }
          });
      } else {
        if (getSessionKey("userRole") === "legalrepresent") {
          if (props.postDataToParent) {
            props.postDataToParent(identityProofObj, file, documentData);
          }
        } else {
          setSessionKey("identityProofObj", JSON.stringify(identityProofObj));
          if (getSessionKey("userRole") === "advisor") {
            navigate("/signup/confirm-details");
          } else {
            navigate("/signup/details-form/residence-proof");
          }
        }
      }
    },
  });
  const docTypeRadio: RadioField[] = [
    {
      value: "passport",
      label: `${t("identityproof_passportTitle")}`,
    },
    {
      value: "identity card",
      label: `${t("identityproof_identityTitle")}`,
    },
  ];
  const identityDocChange = (e: any) => {
    if (e.target.value === "passport") {
      setDocType("passport");
    }
    if (e.target.value === "identity card") {
      setDocType("identity card");
    }
  };

  const handleFileUpload = (file: any, fileData: DocumentDataType | null) => {
    setDocumentData(fileData);
    setFile(file);
    setPreviewFileURL(fileData === null ? "" : fileData?.fileurl);
    if (docType.toLowerCase() === "passport") {
      formik.setFieldValue("passportFile", file);
    } else if (docType.toLowerCase() === "identity card") {
      formik.setFieldValue("idFile", file);
    }
  };
  useEffect(() => {
    let userDetails: any;
    if (getSessionKey("settingType") === "Identity") {
      userDetails = [userData];
    } else if (getSessionKey("userRole") === "legalrepresent") {
      userDetails = props.data;
    } else {
      setStep(props.currentStep!);
      userDetails = getSessionKey("identityProofObj")
        ? getSessionKey("identityProofObj")
        : getSessionKey("userExistData");
    }
    if (userDetails !== null) {
      setDocType(
        (userDetails.identityProofType
          ? userDetails.identityProofType
          : userDetails[0].userdetail.document[0].documentsubtype
        ).toLowerCase()
      );
    } else {
      setDocType("passport");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let userDetails: any;
    if (getSessionKey("settingType") === "Identity") {
      userDetails = [userData];
    } else if (getSessionKey("userRole") === "legalrepresent")
      userDetails = props.data;
    else
      userDetails = getSessionKey("identityProofObj")
        ? getSessionKey("identityProofObj")
        : getSessionKey("userExistData");

    console.log("userDetails", userDetails);
    if (userDetails !== null) {
      getImageFileFromUrl(
        userDetails.documentFileURL
          ? userDetails.documentFileURL
          : userDetails[0].userdetail.document[0].documenturl,
        userDetails.documentFileName
          ? userDetails.documentFileName
          : userDetails[0].userdetail.document[0].fileName
      ).then((fileObj) => {
        if (fileObj) {
          const prefillDocumentData = {
            id: "" + fileObj.lastModified,
            fileurl: userDetails.documentFileURL
              ? userDetails.documentFileURL
              : userDetails[0].userdetail.document[0].documenturl,
            filename: userDetails.documentFileName
              ? userDetails.documentFileName
              : userDetails[0].userdetail.document[0].fileName,
            filesize: "" + fileObj.size,
            filetype: userDetails.documentFileType
              ? userDetails.documentFileType
              : userDetails[0].userdetail.document[0].fileType,
          };

          const prefillUploadedDocumentData = {
            id: "" + fileObj.lastModified,
            name: userDetails.documentFileName
              ? userDetails.documentFileName
              : userDetails[0].userdetail.document[0].fileName,
            size: "" + fileObj.size,
            type: userDetails.documentFileType
              ? userDetails.documentFileType
              : userDetails[0].userdetail.document[0].fileType,
          };
          // console.log('prefillDocumentData', prefillDocumentData);
          if (
            (userDetails.identityProofType
              ? userDetails.identityProofType
              : userDetails[0].userdetail.document[0].documentsubtype
            ).toLowerCase() === docType.toLowerCase()
          ) {
            setPrefillFileObj(fileObj);
            setPrefillUploadedFile(prefillUploadedDocumentData);
            handleFileUpload(fileObj, prefillDocumentData);
          } else {
            setPrefillFileObj(null);
            setPrefillUploadedFile(null);
          }
        }
      });
      formik.setFieldValue(
        "documentId",
        userDetails?.id === null
          ? null
          : userDetails?.id
          ? userDetails?.id
          : userDetails[0].userdetail.document[0].id
          ? userDetails[0].userdetail.document[0].id
          : null
      );
      if (
        (userDetails.identityProofType
          ? userDetails.identityProofType
          : userDetails[0].userdetail.document[0].documentsubtype
        ).toLowerCase() === "passport"
      ) {
        // formik.setFieldValue('passportFile', userDetails[0].userdetail.document[0].documenturl);
        formik.setFieldValue(
          "passport",
          userDetails.documentNo
            ? userDetails.documentNo
            : userDetails[0].userdetail.document[0].documentnumber
        );
        formik.setFieldValue(
          "expiryDatePassport",
          dayjs(
            formatDate(
              userDetails.documentExpiryDate
                ? userDetails.documentExpiryDate
                : userDetails[0].userdetail.document[0].expirydate
            )
          )
        );
      } else if (
        (userDetails.identityProofType
          ? userDetails.identityProofType
          : userDetails[0].userdetail.document[0].documentsubtype
        ).toLowerCase() === "identity card"
      ) {
        // formik.setFieldValue('idFile', userDetails[0].userdetail.document[0].documenturl);
        formik.setFieldValue(
          "idNum",
          userDetails.documentNo
            ? userDetails.documentNo
            : userDetails[0].userdetail.document[0].documentnumber
        );
        formik.setFieldValue(
          "expiryDateIDProof",
          dayjs(
            formatDate(
              userDetails.documentExpiryDate
                ? userDetails.documentExpiryDate
                : userDetails[0].userdetail.document[0].expirydate
            )
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docType]);

  async function getImageFileFromUrl(imageUrl: any, fileName: any) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl, {
        mode: "no-cors",
      });
      // fetch(imageUrl);
      const blob = await response.blob();

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const formatDate = (date: Date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="lyt-main typ-details-form">
      <div className={`${styles.section}`}>
        {getSessionKey("userRole") !== "legalrepresent" && (
          <div className={`${styles.sectionHead}`}>
            <h1 className={`${styles.pgTitle}`}>
              {t("identityproof_pgTitle")}
            </h1>
          </div>
        )}
        <div className="bs-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-body">
              <div className="form-section">
                <h3 className="frm-sec-title">
                  {t("identityproof_documnentType")}
                </h3>
                <div className="form-group">
                  <RadioGroupField
                    name="document-type"
                    value={docType.toLowerCase()}
                    radioField={docTypeRadio}
                    radioChange={identityDocChange}
                  ></RadioGroupField>
                </div>
              </div>
              {docType.toLowerCase() === "passport" ? (
                <>
                  <div className="form-section">
                    <h3 className="frm-sec-title">
                      {t("identityproof_enterPassportDetails")}
                    </h3>
                    <div className="form-group">
                      <FormControl variant="standard">
                        <TextField
                          id="passport"
                          name="passport"
                          label={t("identityproof_enterPassportNumber")}
                          placeholder={t("identityproof_enterPassportNumber")}
                          value={formik.values.passport}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.passport &&
                            Boolean(formik.errors.passport)
                          }
                          helperText={
                            formik.touched.passport && formik.errors.passport
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <div className="bs-datepicker">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}
                          localeText={{
                            okButtonLabel: `${t(
                              "identityproof_calenderDoneBtn"
                            )}`,
                            cancelButtonLabel: `${t(
                              "identityproof_calenderCloseBtn"
                            )}`,
                          }}
                        >
                          <DatePicker
                            className={
                              formik.errors.expiryDatePassport &&
                              formik.touched.expiryDatePassport
                                ? "Mui-error"
                                : ""
                            }
                            format="MM-DD-YYYY"
                            label={t("identityproof_expiryDate")}
                            value={formik.values.expiryDatePassport}
                            onChange={(date) => {
                              formik.setFieldValue("expiryDatePassport", date);
                            }}
                            // onClose={() => {
                            //   // formik.setFieldTouched(
                            //   //   "expiryDatePassport",
                            //   //   true
                            //   // );
                            //   console.log(formik.errors, "Formik Errors!!!");
                            // }}
                            minDate={dayjs().add(0, "day")}
                            slotProps={{
                              field: {
                                readOnly: true,
                              },
                            }}
                          />
                          {formik.touched.expiryDatePassport &&
                          formik.errors.expiryDatePassport ? (
                            <span className="MuiFormHelperText-root Mui-error">
                              {formik.errors.expiryDatePassport}
                            </span>
                          ) : null}
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <h3 className="frm-sec-title">
                      {t("identityproof_uploadPassport")}
                    </h3>
                    <div className="form-group">
                      <DocumentUpload
                        id="passportFile"
                        value={formik.values.passportFile}
                        getFileData={handleFileUpload}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.passportFile &&
                          Boolean(formik.errors.passportFile)
                        }
                        helperText={
                          formik.touched.passportFile &&
                          formik.errors.passportFile
                        }
                        prefillUploadedFileData={prefillUploadedFile}
                        prefillFileObjData={prefillFileObj}
                        previewFileURL={previewFileURL}
                      ></DocumentUpload>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {docType.toLowerCase() === "identity card" ? (
                <>
                  <div className="form-section">
                    <h3 className="frm-sec-title">
                      {t("identityproof_enterIdentityDetails")}
                    </h3>
                    <div className="form-group">
                      <FormControl variant="standard">
                        <TextField
                          id="idNum"
                          label={t("identityproof_enterIdentityNumber")}
                          placeholder={t("identityproof_enterIdentityNumber")}
                          value={formik.values.idNum}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.idNum && Boolean(formik.errors.idNum)
                          }
                          helperText={
                            formik.touched.idNum && formik.errors.idNum
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <div className="bs-datepicker">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}
                          localeText={{
                            okButtonLabel: `${t(
                              "identityproof_calenderDoneBtn"
                            )}`,
                            cancelButtonLabel: `${t(
                              "identityproof_calenderCloseBtn"
                            )}`,
                          }}
                        >
                          <DatePicker
                            className={
                              formik.errors.expiryDateIDProof &&
                              formik.touched.expiryDateIDProof
                                ? "Mui-error"
                                : ""
                            }
                            format="MM-DD-YYYY"
                            label={t("identityproof_expiryDate")}
                            value={formik.values.expiryDateIDProof}
                            onChange={(date) =>
                              formik.setFieldValue("expiryDateIDProof", date)
                            }
                            // onClose={() => {
                            //   formik.setFieldTouched("expiryDateIDProof", true);
                            // }}
                            minDate={dayjs().add(0, "day")}
                            slotProps={{
                              field: {
                                readOnly: true,
                              },
                            }}
                          />
                          {formik.touched.expiryDateIDProof &&
                          formik.errors.expiryDateIDProof ? (
                            <span className="MuiFormHelperText-root Mui-error">
                              {formik.errors.expiryDateIDProof}
                            </span>
                          ) : null}
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <h3 className="frm-sec-title">
                      {t("identityproof_uploadIdentity")}
                    </h3>
                    <div className="form-group">
                      <DocumentUpload
                        id="idFile"
                        value={formik.values.idFile}
                        getFileData={handleFileUpload}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.idFile && Boolean(formik.errors.idFile)
                        }
                        helperText={
                          formik.touched.idFile && formik.errors.idFile
                        }
                        prefillUploadedFileData={prefillUploadedFile}
                        prefillFileObjData={prefillFileObj}
                        previewFileURL={previewFileURL}
                      ></DocumentUpload>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="form-foot">
              <Button
                btnStyle="primary"
                type="submit"
                disabled={!formik.isValid}
              >
                {t("identityproof_proceedBtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default IdentityProof;
