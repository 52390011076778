import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setSessionKey } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

function CreateNewPin() {
  const { loading, setLoading } = useLoader();
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const { userData, setUserData } = useLoader();
  const navigate = useNavigate();
  const [btnValid, setBtnValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");
  const location = useLocation();
  const data: any = location.state;
  /* const initialValues = {
    otpPin: '',
    confirmOtpPin: ''
  };
  const validationSchema = Yup.object().shape({
    otpPin: Yup.string()
      .required('OTP is required')
      .length(4, 'OTP must be exactly 4 characters'),
    confirmOtpPin: Yup.string()
      .oneOf([Yup.ref('otpPin')], 'OTPs must match')
      .required('Confirm OTP is required')
  }); */
  useEffect(() => {
    setBtnValid(otp === confirmOtp && otp.length === 4);
  }, [otp, confirmOtp]);

  const handleClick = () => {
    setLoading(true);
    if (data !== null) {
      if (data.source === "forgot-pin") {
        const payload = {
          pin: confirmOtp,
          email: data.email, // current user id
        };
        axios
          .patch(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/forgotPin",
            payload
          )
          .then((res) => {
            // console.log('res', res);
            if (res.data.success) {
              let historyData = {
                reference: "forgot pin changed",
              };
              navigate("/acknowledgement", { state: historyData });
            } else {
              setShowError(true);
            }
          })
          .catch((error) => {
            //formik.errors.email = error.message
            setShowError(true);
            console.error("Error fetching data:", error.message);
          });
      } else {
        //navigate("/profile-update/add-profile-picture");
        const userData = getSessionKey("userData");
        const payload = {
          type: "update", // type :- [set,update]
          pin: confirmOtp,
          id: userData.id, // current user id
        };
        axios
          .patch(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/pin",
            payload,
            {
              headers: {
                Authorization: `Bearer ${
                  getSessionKey("authToken").accesstoken
                }`,
              },
            }
          )
          .then((res) => {
            // console.log('res', res);
            if (res.data.success) {
              //navigate("/signup/details-form/ubo-details");
              /* setIsConfirmClicked(true);
              setIsDisabled(true);
              props.onConfirm();
              props.onDataFromChild(values.email); */
              // navigate("/profile-update/add-profile-picture");
              setLoading(false);
              setShowError(false);
              const userData = getSessionKey("userData");
              userData.pin = confirmOtp;
              setUserData(userData);
              setSessionKey("userData", userData);
              let historyData = {
                reference: "pin changed",
              };
              navigate("/acknowledgement", { state: historyData });
            } else {
              /* setIsDisabled(false);
              setIsConfirmClicked(false);
              formik.errors.email = res.data.message */
              setLoading(false);
              setShowError(true);
            }
          })
          .catch((error) => {
            setLoading(false);
            setShowError(true);
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              navigate("/enter-pin");
            } else {
              console.error("Error fetching data:", error.message);
            }
          });
      }
    } else {
      //navigate("/profile-update/add-profile-picture");
      const userData = getSessionKey("userData");
      const payload = {
        type: "update", // type :- [set,update]
        pin: confirmOtp,
        id: userData.id, // current user id
      };
      axios
        .patch(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/pin",
          payload,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            },
          }
        )
        .then((res) => {
          // console.log('res', res);
          if (res.data.success) {
            //navigate("/signup/details-form/ubo-details");
            /* setIsConfirmClicked(true);
            setIsDisabled(true);
            props.onConfirm();
            props.onDataFromChild(values.email); */
            // navigate("/profile-update/add-profile-picture");
            setLoading(false);
            setShowError(false);
            const userData = getSessionKey("userData");
            userData.pin = confirmOtp;
            setUserData(userData);
            setSessionKey("userData", userData);
            let historyData = {
              reference: "pin changed",
            };
            navigate("/acknowledgement", { state: historyData });
          } else {
            /* setIsDisabled(false);
            setIsConfirmClicked(false);
            formik.errors.email = res.data.message */
            setLoading(false);
            setShowError(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setShowError(true);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };

  const [step, setStep] = useState(1);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>

      <div className="lyt-main">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <div className={`${styles.titleWrap}`}>
              <h1 className={`${styles.pgTitle}`}>
                {t("profilesettings_createPinPgTitle")}
              </h1>
            </div>
            <p className={`${styles.subTitle}`}>
              {t("profilesettings_createPinPgSubTitle")}
            </p>
          </div>
          <div className="bs-form typ-pin">
            <form>
              <div className="form-body">
                <div className="form-group">
                  <div className={`${showError ? "invalid" : ""} otp-wrap`}>
                    <label htmlFor="otp">
                      {t("profilesettings_createNewPin")}
                    </label>
                    <OtpInput
                      // id="otpPin"
                      // name="otpPin"
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    <label htmlFor="confirmOtp">
                      {t("profilesettings_confirmNewPin")}
                    </label>
                    <OtpInput
                      // id="confirmOtpPin"
                      // name="confirmOtpPin"
                      value={confirmOtp}
                      onChange={setConfirmOtp}
                      numInputs={4}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    {showError && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("profilesettings_incorrectPin")}
                      </span>
                    )}
                    {otp !== confirmOtp && confirmOtp.length === 4 && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("profilesettings_validationPin")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="button"
                  onClick={handleClick}
                  disabled={!btnValid}
                >
                  {t("profilesettings_createPinBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewPin;
